import React, { useEffect, useState, useRef, useMemo } from 'react'
import { flow } from 'fp-ts/lib/function'
import { useHistory } from 'react-router-dom'
import useConfig from '../hooks/useConfig'
import useGetParams from '../hooks/useGetParams'
import Image from './Image'
import LazySVG from './LazySVG'
import { useAdmin, useApi, useDimensions, useInput } from './hooks'
import DetailsPane from './DetailsPane'
import Button from './Button'
import Input from './Input'
import Compass from './Compass'
import { useWhyDidYouUpdate } from 'use-why-did-you-update'

function RAF(predicate: any) {
    window.requestAnimationFrame(() => window.requestAnimationFrame(predicate))
}

type TypeSequenceProps = {
    sequence_id?: string
}

// const resizeObj = { width: 576, height: 324 }
// const resizeObj = { width: 1152, height: 648 }
const imageSizeArray = [
    { width: 288, height: 162 },
    { width: 576, height: 324 },
    { width: 1152, height: 648 },
]

// @TODO
// const image_thread_max = 3

const withOffsetFactory =
    (start: number) =>
    (end: number) =>
    (_offset: number = 0) =>
    (number: number) => {
        const offset = Number(_offset || 0)
        const candidate = number + offset
        if (offset === 0) {
            return number
        } else if (candidate < start) {
            const diff = start - candidate
            return end + 1 - diff
        } else if (candidate > end) {
            const diff = candidate - end
            return start - 1 + diff
        }
        return candidate
    }

const Sequence = React.memo(function Sequence(props: TypeSequenceProps) {
    const { admin_overlay, setAdmin } = useAdmin()
    const config = useConfig()
    const {
        sequence_group_id,
        // image,
        sequence_id,
        // zone,
        // sequence_group,
        sequence,
        sequence_array,
        sequence_overlay,
        start,
        end,
        reverse_rotation,
        compass,
        zone_stroke_color,
        zone_stroke_width,
        zone_color,
        zone_color_emphasis,
    } = useGetParams(props)
    const history = useHistory()

    // console.log({ sequence_group_id, image, sequence_id, zone, sequence_group, sequence, sequence_array, start, end, reverse_rotation })

    /**
     *
     * 74 = 15
     *
     * 89 = 0
     *
     * 0  = 89
     *
     * 1 = 88
     *
     * 5 = 84
     *
     * 6 = 83
     *
     *
     *
     */
    const [tick, setTick] = useState<any>(null)
    const [imageSizeIndex, setImageSizeIndex] = useState<number>(0)
    const [currentImage, setCurrentImage] = useState<number>(start)
    const [mouse_starting_position, setMouseStartingPosition] = useState(0)
    const [offset_image, setOffsetImage] = useState(0)
    const target = useRef<any>(null)
    !target.current && (target.current = document.querySelector('#mouse-target'))
    const [mouseTargetRef] = useDimensions({ liveMeasure: true })

    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [selectedZone, setSelectedZone] = useState<any>(null)
    const [tooltipPosition, setTooltipPosition] = useState<any>(null)

    reverse_rotation && console.log({ currentImage }, { end }, end - currentImage)
    const withOffset = withOffsetFactory(Number(start))(Number(end))(
        (sequence?.offset ? Number(sequence?.offset) : 0) + (reverse_rotation ? -(currentImage * 2) - 1 : 0)
    )
    const [loadedImageArray, setLoadedImageArray] = useState<any[]>([])
    const [prefetchedImageArray, setPrefetchedImageArray] = useState<any[]>([])
    // const withOffset = useMemo(() => {
    //     reverse_rotation && console.log({ currentImage }, { end }, end - currentImage)
    //     return withOffsetFactory(Number(start))(Number(end))(
    //         (sequence?.offset ? Number(sequence?.offset) : 0) + (reverse_rotation ? -(currentImage * 2) - 1 : 0)
    //     )
    // }, [start, end, sequence?.offset, sequence?.id, currentImage])

    useEffect(() => {
        // console.log('useEffect 1')
        console.log('sequence changed')

        setSelectedZone(null)
        // setLoadedClickZoneArray([])
        setLoadedImageArray([])
        if (currentImage < start) {
            goToImage(start)
        } else if (currentImage > end) {
            goToImage(end)
        }
    }, [sequence_id])

    useEffect(() => {
        // console.log('useEffect 2')
        setAdmin((c: any) => ({ ...c, displaying_sequence: true }))
        return () => {
            setAdmin((c: any) => ({ ...c, displaying_sequence: false }))
        }
    }, [])

    const previousImage = () => {
        if (reverse_rotation) {
            return currentImage === start ? end : currentImage - 1
        } else {
            return currentImage === end ? start : currentImage + 1
        }
    }
    const nextImage = () => {
        if (reverse_rotation) {
            return currentImage === end ? start : currentImage + 1
        } else {
            return currentImage === start ? end : currentImage - 1
        }
    }
    const goToImage = (image: number) => setCurrentImage(image)
    const goToNextImage = flow(nextImage, goToImage)
    const goToPreviousImage = flow(previousImage, goToImage)

    const sequence_position = sequence_array.findIndex((s) => s.id === sequence_id)
    const up_possible = sequence_position > 0
    const down_possible = sequence_position < sequence_array.length - 1

    const upSequence = sequence_array[sequence_position - 1]
    const downSequence = sequence_array[sequence_position + 1]
    const withOffsetUp = withOffsetFactory(upSequence?.image_start ? Number(upSequence?.image_start) : Number(start))(
        upSequence?.image_end ? Number(upSequence?.image_end) : Number(end)
    )(upSequence?.offset ? Number(upSequence?.offset) : 0)

    const withOffsetDown = withOffsetFactory(downSequence?.image_start ? Number(downSequence?.image_start) : Number(start))(
        downSequence?.image_end ? Number(downSequence?.image_end) : Number(end)
    )(downSequence?.offset ? Number(downSequence?.offset) : 0)

    const handleKeyDown = (event: any) => {
        // console.log(event.key)
        switch (event.key.toLowerCase()) {
            case 'arrowleft':
                if (reverse_rotation) {
                    goToNextImage()
                } else {
                    goToPreviousImage()
                }
                storeOffsetImage()
                break
            case 'arrowright':
                if (reverse_rotation) {
                    goToPreviousImage()
                } else {
                    goToNextImage()
                }
                storeOffsetImage()
                break
            case 'arrowup':
                up_possible && history.push(`${config.base}${sequence_group_id}/${sequence_array[sequence_position - 1]?.id}`)
                break
            case 'arrowdown':
                down_possible && history.push(`${config.base}${sequence_group_id}/${sequence_array[sequence_position + 1]?.id}`)
                break
            default:
                break
        }
    }

    const evalImageCandidate = (candidateImage: number) => {
        const image_start = Number(sequence?.image_start) || 0
        const image_end = Number(sequence?.image_end) || 1
        if (candidateImage > image_end) {
            candidateImage -= image_end
        } else if (candidateImage < image_start) {
            candidateImage = image_end + candidateImage + 1
        }
        return { candidateImage }
    }
    const handleNewCoords = (e: any) => {
        e.preventDefault()
        const cursor_pos = e.pageX || (e.touches && e.touches[0] && e.touches[0].clientX)
        if (target.current) {
            const coords = target.current.getBoundingClientRect()
            const one_image_width = coords.width / (sequence?.image_end || 1)
            let msp = 0
            setMouseStartingPosition((m) => {
                msp = m
                return m
            })
            let offset = 0
            setOffsetImage((o) => {
                offset = o
                return o
            })
            let cI = 0
            setCurrentImage((i) => {
                cI = i
                return i
            })
            const mouse_diff = (msp - (cursor_pos || 0)) * (reverse_rotation ? 1 : 1)
            const image_diff = Math.round(mouse_diff / one_image_width)
            let { candidateImage } = evalImageCandidate(offset + image_diff)
            if (candidateImage !== cI) {
                setCurrentImage(candidateImage)
            }
        }
    }

    const storeOffsetImage = () => {
        let cI = 0
        setCurrentImage((i) => {
            cI = i
            return i
        })
        setOffsetImage(cI)
    }

    const listenToMove = (e: any) => {
        // console.log('listenToMove')
        // e.preventDefault()
        setMouseStartingPosition(e.pageX || e.touches[0].clientX)
        target.current.addEventListener('mousemove', handleNewCoords)
        target.current.addEventListener('touchmove', handleNewCoords)
    }
    const stopListeningToMove = () => {
        // console.log('stopListeningToMove')
        target.current.removeEventListener('mousemove', handleNewCoords)
        target.current.removeEventListener('touchmove', handleNewCoords)
        storeOffsetImage()
    }

    useEffect(() => {
        // console.log('useEffect 3')
        if (target.current) {
            target.current.addEventListener('touchstart', listenToMove)
            target.current.addEventListener('touchend', stopListeningToMove)
            target.current.addEventListener('mousedown', listenToMove)
            target.current.addEventListener('mouseup', stopListeningToMove)
            return () => {
                target.current.removeEventListener('touchstart', listenToMove)
                target.current.removeEventListener('touchend', stopListeningToMove)
                target.current.removeEventListener('mousedown', listenToMove)
                target.current.removeEventListener('mouseup', stopListeningToMove)
            }
        }
    }, [target.current, start, end, reverse_rotation])

    useEffect(() => {
        // console.log('useEffect 4')
        RAF(() => {})
        document.addEventListener('keydown', handleKeyDown)
        config.details_pane_position !== 'tooltip' && setIsOpen(false)
        return () => {
            document.removeEventListener('keydown', handleKeyDown)
        }
    }, [config, sequence, currentImage])

    const [image_url_array, image_overlay_url_array] = useMemo(() => {
        const url_array: any[] = []
        for (let i = start; i <= end; i++) {
            url_array.push({ url: `sequence/${sequence_id}/${String(i).padStart(4, '0')}.jpg`, image_nb: i })
        }
        const overlay_url_array: any[] = []
        if (typeof sequence_overlay?.image_start !== 'undefined' && sequence_overlay?.image_end) {
            for (let i = sequence_overlay?.image_start; i <= sequence_overlay?.image_end; i++) {
                overlay_url_array.push({ url: `sequence_overlay/${sequence_overlay.id}/${String(i).padStart(4, '0')}.png`, image_nb: i })
            }
        }
        return [url_array, overlay_url_array]
    }, [sequence_id, start, end])

    useEffect(() => {
        for (let i = start; i <= end; i++) {
            fetch(`sequence/${sequence_id}/${String(i).padStart(4, '0')}.jpg`).then((r) => {
                setPrefetchedImageArray((arr) => [...arr, { sequence_id: sequence.id, imageSizeIndex, image_nb: i }])
            })
        }
    }, [imageSizeIndex, sequence.id])
    // console.log({ prefetchedImageArray })

    const click_zone_array = useMemo(() => config.click_zone_array.filter((cz) => cz.sequence_id === sequence_id), [sequence_id, start, end])

    const svg_url_array = useMemo(() => {
        const url_array: any[] = []
        for (let i = start; i <= end; i++) {
            url_array.push(
                click_zone_array.map((cz) => {
                    return { click_zone: cz, url: `click_zone/${cz.id}/${String(i).padStart(4, '0')}.svg`, image_nb: i }
                })
            )
        }
        return url_array
    }, [click_zone_array])

    const svg_array = useMemo(() => {
        let url_array = []
        for (let i = start || 0; i <= end; i++) {
            url_array.push({ image_nb: i, url: `click_zone/_concatenated/${sequence_id}/${String(i).padStart(4, '0')}.svg` })
        }
        return url_array
    }, [sequence_id])

    const selectedZoneRef = useRef<any>(null)

    useEffect(() => {
        // console.log('useEffect 5', { selectedZone })
        if (selectedZone) {
            if (config.concatenated_click_zones) {
                // try {
                !selectedZoneRef.current &&
                    (selectedZoneRef.current = document.querySelector(`[data-image-nb="${currentImage}"] path#path-id-${selectedZone.id}`))
                if (selectedZoneRef.current) {
                    config.details_pane_position === 'tooltip' && selectedZone && setTooltipPosition(selectedZoneRef.current?.getBoundingClientRect())
                }
                // } catch (error) {
                // console.error(error)
                // }
            } else {
                !selectedZoneRef.current &&
                    (selectedZoneRef.current = document.querySelector(`[data-click-zone-id="${selectedZone.id}"][data-image-nb="${currentImage}"] path`))
                if (selectedZoneRef.current) {
                    config.details_pane_position === 'tooltip' && selectedZone && setTooltipPosition(selectedZoneRef.current?.getBoundingClientRect())
                }
            }
        }
        return () => {
            selectedZoneRef.current = null
        }
    }, [selectedZone, currentImage])
    useEffect(() => {
        if (selectedZone) {
            if (config.concatenated_click_zones) {
                RAF(() => {
                    try {
                        !selectedZoneRef.current &&
                            (selectedZoneRef.current = document.querySelector(`[data-image-nb="${currentImage}"] path#path-id-${selectedZone.id}`))
                        if (selectedZoneRef.current) {
                            config.details_pane_position === 'tooltip' && selectedZone && setTooltipPosition(selectedZoneRef.current?.getBoundingClientRect())
                        }
                    } catch (error) {
                        console.error(error)
                    }
                })
            }
        }
        return () => {
            selectedZoneRef.current = null
        }
    }, [tick])

    // const [loadedOverlayImageArray, setLoadedOverlayImageArray] = useState<any[]>([])
    // const [loadedClickZoneArray, setLoadedClickZoneArray] = useState<any[]>([])

    const isThisImageOrPreviousOrNext = (image_nb: Number) =>
        withOffset(currentImage) === image_nb || withOffset(nextImage()) === image_nb || withOffset(previousImage()) === image_nb
    const canLoadTheRestOfImages =
        loadedImageArray.find((li) => li.image_nb === currentImage) &&
        loadedImageArray.find((li) => li.image_nb === withOffset(nextImage())) &&
        loadedImageArray.find((li) => li.image_nb === withOffset(previousImage()))
    // const canLoadTheRestOfClickZones =
    //     !click_zone_array?.length ||
    //     (loadedClickZoneArray.includes(currentImage) && loadedClickZoneArray.includes(nextImage()) && loadedClickZoneArray.includes(previousImage()))
    const canLoadTheRest = canLoadTheRestOfImages
    const allImagesForImageSizeLoaded = (index: Number) => {
        return loadedImageArray.filter((li) => li.imageSizeIndex === imageSizeIndex)?.length === end - start + 1
    }
    useEffect(() => {
        allImagesForImageSizeLoaded(imageSizeIndex) && imageSizeIndex < imageSizeArray.length && setImageSizeIndex(imageSizeIndex + 1)
    }, [loadedImageArray])

    const resizeObject = config.progressive_loading ? imageSizeArray[imageSizeIndex] : imageSizeArray[imageSizeArray.length - 1]
    // const canLoadTheRest = canLoadTheRestOfImages && canLoadTheRestOfClickZones

    // console.log({ canLoadTheRestOfImages }, { canLoadTheRestOfClickZones }, { canLoadTheRest })

    /**
     * Loading policy
     *
     * 1. Load 1 large image (current), load the rest of images small incrementally, close to the current one
     * 2. Load current zones, after all the images have loaded, load the zones
     *
     * left_threshold = currentImage
     * right_threshold = currentImage
     * 1st batch is right_threshold + Math.ceil(image_thread_max /2)
     * 1st batch is left_threshold - Math.floor(image_thread_max /2)
     *
     * pool = right_threshold + Math.ceil(image_thread_max /2), when finished increase right threshold by Math.ceil(image_thread_max /2)
     * pool = left_threshold - Math.floor(image_thread_max /2), when finished decrease left threshold by Math.floor(image_thread_max /2)
     *
     *
     * when first image is loaded:
     * loadedImageArray.includes(currentImage)
     */

    const sequence_background_color = config.color_array?.find((c) => c.id === 'sequence_background')?.color
    // console.count('Sequence rendered')

    const click_zone_style = click_zone_array.map((cz) => {
        return `[data-selected-zone-id="${cz.id}"] path#path-id-${cz.id} {
    ${zone_color_emphasis ? `fill: ${zone_color_emphasis} !important; opacity: 0.6` : ''}
}`
    }).join(`
`)
    return (
        <>
            <div
                className="flex items-center justify-center max-w-full max-h-full w-full h-full"
                style={sequence_background_color ? { backgroundColor: sequence_background_color } : undefined}
            >
                {((zone_color || zone_stroke_color || zone_stroke_width) && (
                    <>
                        <style
                            dangerouslySetInnerHTML={{
                                __html: `
.click-zone path{
    ${zone_color ? `fill: ${zone_color} !important;` : ''}
    ${zone_stroke_color ? `stroke: ${zone_stroke_color} !important;` : ''}
    ${zone_stroke_color ? `stroke-width: ${zone_stroke_width}px !important;` : ''}
}
.click-zone path:hover{
    ${zone_color_emphasis ? `fill: ${zone_color_emphasis} !important;` : ''}
}
${click_zone_style}`,
                            }}
                        />
                    </>
                )) ||
                    null}
                {/* <ul className="absolute top-0 left-0" style={{ zIndex: 10 }}>
                    {click_zone_array.map((cz) => (
                        <li
                            key={cz.id}
                            onClick={() => {
                                setIsOpen(true)
                                setSelectedZone(cz)
                            }}
                        >
                            {cz.label}
                        </li>
                    ))}
                </ul> */}
                <div id="mouse-target" ref={mouseTargetRef} className="absolute top-0 left-0 max-w-full max-h-full w-full h-full">
                    {(config.concatenated_click_zones &&
                        svg_array.map(
                            (svg) =>
                                ((canLoadTheRest || isThisImageOrPreviousOrNext(svg.image_nb)) && (
                                    <LazySVG
                                        key={svg.url}
                                        src={svg.url}
                                        onClick={(e: any) => {
                                            const click_zone_id = e.target.id.replace('path-id-', '')
                                            const click_zone = click_zone_array.find((cz) => cz.id === click_zone_id)
                                            if (click_zone?.target_sequence_id) {
                                                const sequence = config.sequence_array.find((s) => s.id === click_zone?.target_sequence_id)
                                                const sequence_group = config.sequence_group_array.find((sg) => sg.id === sequence?.sequence_group_id)
                                                sequence && history.push(`/${sequence_group?.url || sequence_group?.id}/${sequence.url || sequence.id}`)
                                            } else {
                                                setIsOpen(true)
                                                setSelectedZone(click_zone)
                                                config.details_pane_position && setTooltipPosition(e.currentTarget.getBoundingClientRect())
                                            }
                                        }}
                                        className={
                                            'cursor-pointer absolute pointer-events-none max-w-full max-h-full w-full h-full object-contain' +
                                            (svg.image_nb === currentImage ? ' click-zone' : ' no-click-zone')
                                        }
                                        visible={svg.image_nb === currentImage}
                                        image_nb={svg.image_nb}
                                        // onLoad={() => setLoadedClickZoneArray((arr: any[]) => [...arr, url.image_nb])}
                                        onLoad={() => setTick(tick + 1)}
                                        selected_zone_id={selectedZone?.id}
                                    />
                                )) ||
                                null
                        )) ||
                        svg_url_array.map((url_group: any[], group_index) =>
                            url_group.map((urlObj, index) => {
                                return (
                                    ((canLoadTheRest || isThisImageOrPreviousOrNext(urlObj.image_nb)) && (
                                        <LazySVG
                                            key={urlObj.url + '.' + group_index}
                                            src={urlObj.url}
                                            onClick={(e: any) => {
                                                if (urlObj.click_zone?.target_sequence_id) {
                                                    const sequence = config.sequence_array.find((s) => s.id === urlObj.click_zone?.target_sequence_id)
                                                    sequence && history.push(`/${sequence.sequence_group_id}/${sequence.id}`)
                                                } else {
                                                    setIsOpen(true)
                                                    setSelectedZone(urlObj.click_zone)
                                                    console.log(e.currentTarget)
                                                    config.details_pane_position && setTooltipPosition(e.currentTarget.getBoundingClientRect())
                                                }
                                            }}
                                            className={
                                                'cursor-pointer absolute pointer-events-none max-w-full max-h-full w-full h-full object-contain' +
                                                (group_index === currentImage ? ' click-zone' : ' no-click-zone')
                                            }
                                            visible={group_index === currentImage}
                                            click_zone={urlObj.click_zone}
                                            image_nb={urlObj.image_nb}
                                            // onLoad={() => setLoadedClickZoneArray((arr: any[]) => [...arr, urlObj.image_nb])}
                                        />
                                    )) ||
                                    null
                                )
                            })
                        )}
                </div>
                {image_url_array.map((urlObj: any, index: number) => {
                    return (
                        ((isThisImageOrPreviousOrNext(urlObj.image_nb) ||
                            prefetchedImageArray.find((pi) => pi.image_nb === index && pi.sequence_id === sequence.id)) && (
                            <Image
                                key={urlObj.url}
                                src={urlObj.url}
                                alt={`Sequence ${sequence?.label}, Image ${index}`}
                                // resize={{ width: 768, height: 432 }}
                                resize={resizeObject}
                                className={
                                    'max-w-full max-h-full w-full h-full object-contain' +
                                    (index === withOffset(currentImage)
                                        ? ' current-image'
                                        : isThisImageOrPreviousOrNext(urlObj.image_nb)
                                        ? ' hidden'
                                        : ' hidden')
                                }
                                onLoad={() =>
                                    +console.log(`image ${urlObj.image_nb} loaded`) ||
                                    setLoadedImageArray((arr) => [...arr, { imageSizeIndex, image_nb: urlObj.image_nb, sequence_id: sequence.id }])
                                }
                            />
                        )) ||
                        null
                    )
                })}
                <div id="sequence-overlay" className="absolute top-0 left-0 max-w-full max-h-full w-full h-full pointer-events-none">
                    {image_overlay_url_array.map((urlObj: any, index: number) => {
                        return (
                            ((canLoadTheRest || isThisImageOrPreviousOrNext(urlObj.image_nb)) && (
                                <Image
                                    key={urlObj.url}
                                    src={urlObj.url}
                                    alt={`Sequence Overlay ${sequence?.label}, Image ${index}`}
                                    // resize={{ width: 768, height: 432 }}
                                    resize={resizeObject}
                                    className={
                                        'absolute top-0 left-0 max-w-full max-h-full w-full h-full object-contain' +
                                        (index === withOffset(currentImage) ? '' : ' invisible')
                                    }
                                    // onLoad={() =>
                                    //     +console.log(`overlay image ${urlObj.image_nb} loaded`) || setLoadedOverlayImageArray((arr) => [...arr, urlObj.image_nb])
                                    // }
                                />
                            )) ||
                            null
                        )
                    })}
                </div>
                {(compass && <Compass currentImage={currentImage} />) || null}
                {(upSequence && (
                    <Image
                        src={`sequence/${upSequence?.id}/${String(withOffsetUp(currentImage)).padStart(4, '0')}.jpg`}
                        resize={imageSizeArray[imageSizeArray.length - 1]}
                        className="hidden"
                    />
                )) ||
                    null}
                {(downSequence && (
                    <Image
                        src={`sequence/${downSequence?.id}/${String(withOffsetDown(currentImage)).padStart(4, '0')}.jpg`}
                        resize={imageSizeArray[imageSizeArray.length - 1]}
                        className="hidden"
                    />
                )) ||
                    null}

                {(selectedZone && (
                    <DetailsPane
                        position={config.details_pane_position || 'left'}
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        click_zone={selectedZone}
                        tooltipPosition={tooltipPosition}
                        tick={tick}
                    />
                )) ||
                    null}
            </div>
            {(admin_overlay && <SequenceAdminOverlay currentImage={currentImage} withOffsetCurrentImage={withOffset(currentImage)} />) || null}
        </>
    )
})

const SequenceAdminOverlay = (props: any) => {
    const sequenceApi = useApi()
    const offsetInput = useInput(0)

    const { sequence_id } = useGetParams()
    const { currentImage, withOffsetCurrentImage } = props

    return (
        <div className="fixed bottom-0 right-0 p-4 admin-sequence-overlay">
            <dl>
                <dd>Current image: </dd>
                <dt>{currentImage}</dt>
            </dl>
            <dl>
                <dd>Offset image: </dd>
                <dt>{withOffsetCurrentImage}</dt>
            </dl>
            <form onSubmit={(e: any) => +e.preventDefault() || false}>
                <label htmlFor="offset">Offset</label>
                <Input type="number" id="offset" {...offsetInput.bind} />
                <Button
                    onClick={() => {
                        sequenceApi.post('sequence/offset', { sequence_id, offset: offsetInput.value })
                    }}
                >
                    Go
                </Button>
            </form>
        </div>
    )
}

export default Sequence
