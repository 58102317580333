import React, { useEffect, useMemo, useState } from 'react'
import { Switch, Route, Link } from 'react-router-dom'
import ImageMenu from './ImageMenu'
import Sequence from './Sequence'
import {
    ConfigContext,
    defaultConfigContextValue,
    AdminContext,
    defaultAdminContextValue,
    TypeAdminContext,
    NotificationsContext,
    defaultNotificationsContextValue,
    TypeNotificationsContext,
} from '../contexts'
import { TypeConfig, TypeConfigContext, TypeSequence, TypeNotification } from '../types'
import Home from './Home'
import Navigation from './Navigation'
import { useData, TypeUseData, useApi } from './hooks'
import AdminBar from './AdminBar'
import Notifications from './Notifications'
import Settings from './Settings'
import Import from './Import'
import Spinner from './Spinner'
import Footer from './Footer'
import Helmet from 'react-helmet'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import SequenceButtons from './SequenceButtons'

type TypeAppProps = any

const App = React.memo(function App(props: TypeAppProps) {
    const [selected, setSelected] = useState(null)
    const [admin, setAdmin] = useState<any>({ ...defaultAdminContextValue, setSelected, selected })
    useEffect(() => {
        console.log('Checking', admin.edit, selected)
        !admin.edit && selected && setSelected(null)
    }, [admin.edit])
    const configData = useData<TypeConfig>({ src: 'config.json' })
    const config = configData.data
    const configApi = useApi()
    const saveConfig = (param: TypeConfigContext) => {
        const { setConfig, refresh, saveConfig, ...payload } = param
        return configApi.post('config', payload)
    }
    const configContextValue: TypeConfigContext = useMemo(() => {
        return config
            ? {
                  ...config,
                  sequence_array: config?.sequence_array.sort((a: TypeSequence, b: TypeSequence) => a.index - b.index),
                  setConfig: configData.setData,
                  refresh: configData.refresh,
                  saveConfig,
              }
            : defaultConfigContextValue
    }, [config])
    const [notification, setNotification] = useState<TypeNotificationsContext>(defaultNotificationsContextValue)
    const notificationData = useData<any>({ url: 'notifications' }, [admin.enabled])
    useEffect(() => {
        notificationData?.data && setNotification((s: any) => ({ ...s, notification_array: notificationData.data, refresh: notificationData.refresh }))
    }, [notificationData?.data])
    const notificationContextValue: TypeNotificationsContext = useMemo(() => {
        return {
            ...notification,
            setNotificationArray: (notification_array: TypeNotification[]) => setNotification((s: any) => ({ ...s, notification_array })),
        }
    }, [notification])

    return (
        <ConfigContext.Provider value={configContextValue}>
            <DndProvider backend={HTML5Backend}>
                <Helmet>
                    <title>{config?.title || 'Perfectsen.se App'}</title>
                </Helmet>
                <AdminContext.Provider value={useMemo(() => ({ ...admin, selected, setAdmin }), [admin, selected])}>
                    <NotificationsContext.Provider value={notificationContextValue}>
                        {(config && (
                            <>
                                {(admin.enabled && <AdminBar />) || null}
                                <Switch>
                                    <Route path={'/notifications'} exact={true}>
                                        <div className={admin.enabled ? ' bg-gray-100 edit-mode' : ''}>
                                            <Notifications />
                                        </div>
                                    </Route>
                                    <Route path={'/settings'} exact={true}>
                                        <div className={admin.enabled ? ' bg-gray-100 edit-mode' : ''}>
                                            <Settings />
                                        </div>
                                    </Route>
                                    <Route path={'/import'} exact={true}>
                                        <div className={admin.enabled ? ' bg-gray-100 edit-mode' : ''}>
                                            <Import />
                                        </div>
                                    </Route>
                                    <Route path="/" exact={true}>
                                        <div className={'w-full h-full' + (admin.enabled ? ' edit-mode' : '')}>
                                            <Home />
                                        </div>
                                    </Route>
                                    <Route path={['/:sequence_group_id']}>
                                        <div className={'w-full h-full' + (admin.enabled ? ' edit-mode' : '')}>
                                            <main className={'flex flex-col justify-center items-center w-full h-full'}>
                                                <Route path={['/:sequence_group_id?/:sequence_id?/:image?/:zone?']}>
                                                    <Sequence />
                                                    <Navigation />
                                                    <SequenceButtons />
                                                </Route>
                                            </main>
                                        </div>
                                    </Route>
                                </Switch>
                            </>
                        )) || (
                            <div className="flex items-center justify-center w-full h-full">
                                <Spinner className="mr-2" />
                            </div>
                        )}
                    </NotificationsContext.Provider>
                </AdminContext.Provider>
            </DndProvider>
        </ConfigContext.Provider>
    )
})

export default App
