import React from 'react'
import useId from '../hooks/useId'
import Select from 'react-select'

export const Label = function (props: any) {
    return (
        <label
            htmlFor={props.id || props.htmlFor || undefined}
            className={((props.type === 'checkbox' && 'inline-block ml-1') || 'block') + ' text-gray-700 text-sm font-bold mb-2 ' + (props.className || '')}
        >
            {props.label || props.children}
        </label>
    )
}

type TypeInputProps = any

const Input = React.memo(function Input(props: TypeInputProps) {
    const { label, className, ...rest } = props
    const auto_id = useId()
    const id = props.id || auto_id

    if (props.type === 'select') {
        return (
            <div className={className}>
                {(label && <Label id={id}>{label}</Label>) || null}
                <Select {...props} />
            </div>
        )
    }

    if (props.type === 'color') {
        const { label, ..._rest } = rest
        return (
            <>
                <Label className={className}>{props.label || props.children || 'Color'}</Label>
                <div className="input-color-container flex flex-wrap items-stretch w-full mb-4 relative shadow appearance-none leading-tight rounded">
                    <div className="flex -mr-px">
                        <label
                            htmlFor={id}
                            style={{ backgroundColor: props.value || '#FFFFFF' }}
                            className="flex items-center leading-normal bg-grey-lighter rounded rounded-r-none border border-r-0 border-grey-light px-3 whitespace-no-wrap text-grey-dark text-sm w-5 cursor-pointer"
                        />
                    </div>
                    <input
                        {..._rest}
                        type="text"
                        className="flex-shrink flex-grow flex-auto leading-normal w-px border h-10 border-grey-light rounded rounded-l-none px-3 relative  focus:outline-none"
                    />
                    <input id={id} {...rest} />
                </div>
            </>
        )
    }

    return (
        <div className={className}>
            {(label && props.type !== 'checkbox' && (
                <Label htmlFor={id} type={props.type}>
                    {label}
                </Label>
            )) ||
                null}
            <input
                {...props}
                id={id}
                className={
                    (props.type === 'checkbox' && 'shadow border rounded inline-block') ||
                    'shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                }
            />
            {(label && props.type === 'checkbox' && (
                <Label htmlFor={id} type={props.type}>
                    {label}
                </Label>
            )) ||
                null}
        </div>
    )
})

export default Input
