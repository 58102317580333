export interface TypeDimensions {
    width: number
    height: number
    top: number
    left: number
    x: number
    y: number
    right: number
    bottom: number
}
export function getDimensions(node: any): TypeDimensions {
    const rect: any = node.getBoundingClientRect()

    return {
        width: rect.width,
        height: rect.height,
        top: 'y' in rect ? rect.y : rect.top,
        left: 'x' in rect ? rect.x : rect.left,
        x: 'x' in rect ? rect.x : rect.left,
        y: 'y' in rect ? rect.y : rect.top,
        right: rect.right,
        bottom: rect.bottom,
    }
}

export const clamp = (value: number, min: number, max: number) => Math.min(Math.max(value, min), max)
